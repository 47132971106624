/*  ===== MOBILE styles (inherited by all devices) ===== 
/*  ===== See tablet, desktop, and print @media queries at the bottom.


/*   ----- Imports  -----  */
@font-face {
	font-family: 'Computer Modern Serif Slanted';
	src: url('./font/cmunsl.eot');
	src: url('./font/cmunsl.eot?#iefix') format('embedded-opentype'),
		 url('./font/cmunsl.woff') format('woff'),
		 url('./font/cmunsl.ttf') format('truetype'),
		 url('./font/cmunsl.svg#cmunsl') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Serif Slanted';
	src: url('./font/cmunbl.eot');
	src: url('./font/cmunbl.eot?#iefix') format('embedded-opentype'),
		 url('./font/cmunbl.woff') format('woff'),
		 url('./font/cmunbl.ttf') format('truetype'),
		 url('./font/cmunbl.svg#cmunbl') format('svg');
	font-weight: bold;
	font-style: normal;
}
/* @import url(//fonts.googleapis.com/css?family=Font+Name); */

/* @import '../node_modules/modern-normalize/modern-normalize.css'; */
/* npm install modern-normalize 
                              Resets elements for browser consistancy.  
                              http://nicolasgallagher.com/about-normalize-css/ 
                            */

/* @import "component1.css";   Component-specific stylesheets, if any */


/*   ----- Variables  -----  */
:root {
  --custom-color: ;
  --custom-size: ;
}

/*  ----- Viewport ----- elements inherited by all remaining elements ----- */
body {
  /* Can alternatively define in html {} or :root {} */
  color: rgb(66, 64, 64);
  background-color: rgba(218, 218, 218, 0.163);
  font-family: 'Computer Modern Serif Slanted';
  font-size: 14px;
  margin: 3px;
  padding: 20px;
  /* text-align: center; */
  /* border: dashed rgb(37, 34, 34); */
}

/* ----- Anchors ----- Text links */
/* a {}
a:link {}
a:hover {}
a:active {}
a:visited {} */

/*  ----- Navigation ----- Override text links for navigation only.  */
nav {
  /* background-color: rgba(218, 218, 218, 0.163); */
  color: rgb(82, 82, 32);
  border: rgb(66, 64, 64);
  font-size: 20px;
  font-weight: bold;
  line-height: 12px;
  margin: 1%;
  padding: 1%;
  text-decoration: none;
  vertical-align: baseline;
  list-style-type: none;
}

nav a {
  /* background-color: aliceblue; */
  color: rgb(13, 9, 70);
  /* border: rgb(66, 64, 64); */
  font-size: 20px;
  font-weight: bold;
  line-height: 12px;
  margin: 1%;
  padding: 1%;
  /* text-decoration: underline #383535; */
  vertical-align: baseline;
  /* list-style-type: none; */
}

nav ul {
  list-style-type: none;
  padding-inline-start: 0%;
}

nav a:hover {
  /* background-color: rgba(20, 29, 68, 0.808); */
  /* color: rgba(220, 220, 224, 0.726); */
  border: rgb(64, 65, 66);
  font-size: 20px;
  font-weight: bold;
  line-height: 12px;
  margin: 1%;
  padding: 1%;
  /* text-decoration: underline #383535; */
  vertical-align: baseline;
  /* list-style-type: none; */
}

/*  ----- Page layout blocks -----  */
header {
  background-color: rgba(218, 218, 218, 0.163);
  color: rgb(78, 47, 9);
  font-size: larger;
  font-weight: bolder;
  margin: 0px;
  padding-top: 1%;
  border: 0px;
  text-align: center;
}



main {

}

.aaae {
  /* background-color: rgba(37, 37, 37, 0.678); */
  /* color: rgb(228, 223, 223); */
  /* border: dashed rgba(202, 201, 201, 0.952); */
  padding: 4%;
  list-style-type: disc;
  margin:auto;
  width: 50%;
}

img.ri
{
	/* position: absolute; */
	max-width: 80%;
  margin:auto;
  margin-top: 3%;
  display: block;
	border-radius: 3px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.9);
  padding: 1%;
}
figcaption {
  padding-top: 2%;
  text-align: center;
}
/* section {}
  article {}
    #topic1 {}
    #topic2 {}
    #gallery1 {}
    #gallery2 {}
div {}
aside {}  */
footer {
  /* background-color: aliceblue; */
  /* color: rgb(78, 47, 9); */
  font-size: larger;
  font-weight: bolder;
  margin: 0px;
  padding: 0%;
  border: 0px;
  text-align: center;
  background-color: rgba(218, 218, 218, 0.163);
}
footer p{
  /* margin: 3%; */
  padding: 3%;
}

/*  -----  Typography  ----- */
/* p {}
br {}
ol, ul, dl {} */

li {
  padding: 0%;
  margin: 0%
}

h1, h2 {
  margin: 0%;
}

ul {
  margin: 0%;
}

p {
  margin: 0%;
}

/* dt {}
dd {} */

/* h1, h2, h3, h4, h5, h6 {} Define consistant font family. */
/* h1 {}
h2 {}
h3 {}
h4 {}
h5 {}
h6 {}

abbr {}
acronym {}
address {}
blockquote {}
  q {}
  cite {}
em {}
hr {}
mark {}
s {}
span {}
strong {}
sub {}
sup {}
time {} */

/*  ----- Coding or instructional typography ----- */
/* pre {}
code {}
kbd {}
samp {}
var {}
del {} 
ins {} */

/*  ----- Table ----- */
table {
  border-collapse: collapse;
}

/* caption {} */
/* thead {border: 3px;
  padding: 2%;}
tbody {border: 3px;
  padding: 2%;}
tfoot {} */
tr {
  border: 3px;
  padding: 2%;
}

tr:nth-child(even) {}

th {
  border: 3px;
  padding: 2%;
}

td {
  border: 3px;
  padding: 2%;
}

nav li {
  padding: 1%;
  margin: 1%;
}

body {
  padding: 0%;
  margin: 0%;

}

main {
  /* background-color: rgb(82, 82, 32); */
}

@media all and (min-width: 600px) {
  nav li {
    display: inline;
    list-style-type:none;
    padding: 0%;
    margin: 0%;
  }
}

/* td:nth-child(1) {} */
colgroup {}

data {}

datalist {}


/*  ----- Form ----- */
form {}

fieldset {}

legend {}

label {}

button {
  padding: 1%;
  /* font-family: 'Courier New', Courier, monospace; */
  font-size: 14px;
  margin: 1%
}

footer li {
  list-style-type:none;
}

input {
  padding: 1%;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  margin: 1%
}

output {}

meter {}

progress {}

select {}

optgroup {}

option {}

textarea {}

/* ----- Media ----- */
/* figure {}
  figcaption {}
img {}
svg {}
picture {}
  source {}
iframe {}
video {}
audio {}
embed {}
object {} */


/*  ----- Classes for alignment, positioning, widths, grids, embellishments, and animation -----  */
/* .floatright {}
.floatleft {}
.center-middle {}

.ten {}
.twenty {}
.thirty {}
.forty {}
.fifty {}
.sixty {}
.seventy {}
.eighty {}

.radius {}
.circle {}
.boxshadow {}
.tshadow {} 
.gradient {}
.shape {} */

/* @keyframes App-logo {} */

/*   ===== TABLET  media query overrides mobile styles ===== */
/* @media all and (max-width: 600px) {
  li {
    display: inline;
  }
} */

/*   ===== DESKTOP  media query overrides mobile and tablet styles ===== */
/* @media all and (min-width: 1080px) {} */

/*   ===== PRINT  media query overrides previous styles =====  */
/* @media print {} */